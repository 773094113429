@import '../../../variables';

.MainDashboardHeader {
  box-shadow: $navigation-box-shadow;
  position: relative;
  z-index: 5;
  background-color: $white;
  justify-content: space-between;
  font-size: 0.9em;
  font-family: $font-family-metropolis;

  @media (max-width: $breakpoint-tablet) {
    background-color: $dark-blue;
    padding: 1em;
    font-size: 0.9em;
  }

  @media (min-width: $breakpoint-tablet) {
    .ApplicationLogo {
      margin-right: 1em;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 1.55vw;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 0.6em;
  }

  .NavBar {
    @media (max-width: $breakpoint-tablet) {
      display: none !important;
    }

    .NavBar-Btn {
      margin: 0 1vw;
      white-space: nowrap;
      color: $dark-blue;
      padding: 1.3em 0;
      text-decoration: none;
      position: relative;
      font-weight: 500;
      transition: 0.3s ease-in-out;

      .fa-angle-down {
        vertical-align: middle;
      }
    }

    .BrowseNavigation:hover .NavBar-Btn,
    .NavBar-Btn:hover {
      color: $secondary;
    }

    .NavBar-Btn::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 0;
      background: $secondary;
      transition: 0.3s ease-in-out;
    }

    .NavBar-Btn:hover::after {
      height: 0.4em;
    }

    .BrowseNavigation .NavBar-Btn:hover::after {
      display: none;
    }
  }

  .MobileMenuBtn {
    display: none;
    color: $brown;
    font-size: 24px;

    @media (max-width: $breakpoint-tablet) {
      display: inline-block;
    }
  }

  .AgeVerifiedContainer {
    width: 2em;
    height: 2em;
    position: relative;
    margin: 0 0 0 0.6em;
    padding: 0.2em;
    color: $white;
    background: $ageVerifiedIcon;
    border-radius: 0.3em;
    cursor: pointer;

    @media (max-width: $breakpoint-mobile) {
      width: 1.4em;
      height: 1.4em;
    }

    svg {
      vertical-align: middle;
      pointer-events: none; // datalayer click bug
    }

    .AgeVerifiedContent {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      z-index: 3;
      width: 100vw;
      max-width: 14em;
      color: $white;
      background: $ageVerifiedIcon;
      padding: 0.5em;
      border-radius: 0.3em;
      text-align: center;
      line-height: 1.5;
    }

    &:hover {
      .AgeVerifiedContent {
        display: block;
      }
    }
  }

  .SearchIcon {
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    color: $primary;
    margin: 0 1em;

    .CloseSearchSvg {
      width: 70%;
      height: 70%;
    }

    &>* {
      pointer-events: none;
    }

    @media (max-width: $breakpoint-tablet) {
      color: $white;
      margin: 0 1em;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .UserActions .UserAction,
  .ModalOpenBtn {
    border-radius: 0.3em;
    padding: 0.4em 0.8em; 
  }

  .ModalOpenBtn {
    text-transform: none;
  }

  .LoginWrapper .UserAction,
  .ModalOpenBtn {
    border: 1px solid $primary;
  }
}
