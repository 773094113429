@import '../../variables';

.UserActions {
  display: none;
  cursor: pointer;
  color: $dark-blue;
  font-size: 1.1em;
  text-align: center;
  white-space: nowrap;

  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }

  &> {
    .UserAction {
      color: $white;
      background: $primary;
      margin: 0 0.8em 0 0;
      transition: 0.3s ease-in-out; // for transform: scale()
      font-weight: 600;
      text-transform: capitalize;

      &:hover {
        color: $white;
        background-color: $secondary;

        @media (min-width: $breakpoint-tablet) {
          transform: scale(1.1);
        }
      }
    }
  }

  .LoginWrapper {
    display: flex;
  }
}

.LoginWrapper .UserAction:hover {
  color: $secondary;
}
