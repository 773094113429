@import '../../variables';

.SceneImageContainer {
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;

  @keyframes progress-load {
    0% {
      width: 0;
      background: var(--progres-bar-color, $light-blue);
    }

    100% {
      width: 100%;
      background: var(--progres-bar-color, $light-blue);
    }
  }

  .ProgressContainer {
    position: relative;
    padding: 0;
    background: transparent;

    .Loading {
      position: relative;
      display: -webkit-box;
      width: 100%;
      height: 3px;
      overflow: hidden;
      animation: progress-load 2.5s infinite;
    }
  }

  .MainView {
    height: 100%;
    width: 100%;

    .PlayButton,
    .OverlayElement {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .ProgressComponent {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .OverlayElement {
      z-index: 10;
    }

    .FullPlayer {
      height: 100%;
      width: 100%;

      .BaseVideoPlayerCore-inner {
        min-height: 0;

        .video-js {
          min-height: auto;

          video {
            object-fit: cover;
          }
        }
      }

      .vjs-loading-spinner {
        display: none;
      }

      .vjs-big-play-button {
        display: none;
      }

      &.Hidden {
        display: none;
      }
    }
  }

  .MouseEventOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}