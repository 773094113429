@import 'src/variables';

.MainSearch {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: #142d59;
  padding: .5em .3em;
  color: #b89e51;
  box-sizing: border-box;

  @media (max-width: 960px) {
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100vh;
      pointer-events: none;
      background: hsla(0, 0%, 100%, .5);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }

  &.isOpen {
    transition: .3s ease-in-out;
    visibility: visible;
    opacity: 1;
  }

  .SearchInputContainer {
    width: 100%;
    position: relative;

    .SearchIcon {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      pointer-events: none;
      color: $secondary;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .DropdownSelect {
    position: relative;
    margin-right: 5px;
    min-width: 5em;
  }

  .searchTerm {
    color: inherit;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: inherit;
    font: inherit; 
    padding: .3em 2.5em .3em .3em;

    &::placeholder {
      color: #c2c6d1;
      opacity: 1;
      /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12-18 */
      color: #c2c6d1;
    } 
  }

  .SearchContainer {
    position: relative;
    max-width: 25em;
    display: flex;
    margin: 0 auto;

    &.hasDropdown {
      font-weight: 800;
      border: none;
      border-bottom: 2px solid $dark-blue;

      @media (max-width: $breakpoint-tablet) {
        & {
          font-size: 90%;
        }
      }
    }
  }

  .searchButton {
    margin: 0 10px;
    color: $dark-blue;
    cursor: pointer;
    font-size: 120%;

    @media (max-width: $breakpoint-tablet) {
      & {
        display: none;
      }
    }
  }

  .SearchResults {
    padding: 10px;
    background: #142d59;
    color: #fff;
    position: absolute;
    z-index: 2000;
    width: 100%;
    top: 105%;
    left: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 400px;
    overflow: auto; 
    border-radius: 10px; 

    .SearchResultsSection {
      display: flex;
      flex-direction: column;
      font-size: 90%;

      .SearchResultsSectionHeader {
        color: $gray;
        margin-bottom: .5em;
        font-weight: 600;
      }

      .SearchResultItem {
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 5px;
        margin-left: 15px;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .NoResults {
    position: absolute;
    z-index: 2000;
    top: 110%;
    left: 0;
    padding: 10px;
    background: #142d59;
    color: #fff;
    width: 100%; 
    border-radius: 10px; 
  }
}
